import JwksClient from 'jwks-rsa';
import { verify } from 'jsonwebtoken';
import getPkce from 'oauth-pkce';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { softwareErrors } from 'dmpconnectjsapp-base/errors';
import {
  logoutSuccess,
  setPersistedConnectorConfiguration,
} from 'dmpconnectjsapp-base/actions';
import env from '../envVariables';
import {
  getAction, setModalError, setMssPscToken, setUserJWT,
} from '../dmpconnect/actions';

import { createError } from '../dmpconnect/errors';
import { errorTypes } from '../dmpconnect/errors/errorConfiguration';
import { objectToQueryParams, generateId, getObjectKeyFromPath } from '../dmpconnect/utils/dataUtils';
import { openIDErrors } from '../dmpconnect/errors/errorConstants';

const pscUserInfosMapper = {
  hpName: 'family_name',
  hpGiven: 'given_name',
  professionIdentifier: 'SubjectRole',
  professionCode: 'codeProfession',
  specialityCode: 'codeSavoirFaire',
  hpInternalId: 'SubjectNameID',
};
const pscLoginCheckMapper = {
  name: 'family_name',
  given: 'given_name',
  rpps: 'SubjectNameID',
};

export default class OidcClient {
  constructor(settings) {
    this.clientId = settings.clientId;
    this.clientSecret = settings.clientSecret;
    this.signkey = settings.signKey;
    this.authorizeEndpoint = settings.authorizeEndpoint;
    this.tokenEndpoint = settings.tokenEndpoint;
    this.jwksEndpoint = settings.jwksEndpoint;
    this.userInfoEndpoint = settings.userInfoEndpoint;
    this.userInfoMethod = settings.userInfoMethod;
    this.endSessionEndpoint = settings.endSessionEndpoint;
    this.scope = settings.scope;
    this.responseType = settings.responseType;
    this.deactivatePCKE = settings.deactivatePCKE || false;
    this.acrValues = settings.acrValues || '';
    this.redirectURI = settings.redirectURI;
    this.logoutUri = settings.logoutURI;
    this.loggedIn = false;
    this.cpxLogin = false;
    this.authBearerUseJWTLogin = false;
    this.callUserInfos = settings.callUserInfos || false;
    if (settings.userInfosMapper) {
      this.userInfosMapper = JSON.parse(settings.userInfosMapper);
    }
    if (settings.loginCheckMapper) {
      this.loginCheckMapper = JSON.parse(settings.loginCheckMapper);
    }
    this.connectorToken = settings.connectorToken || undefined;
    this.apiType = settings.apiType;
    this.dcParams = settings.dcParams;
    this.overridePscUserInfos = undefined;
  }

  setConfig(settings) {
    this.clientId = settings.clientId;
    this.clientSecret = settings.clientSecret;
    this.signkey = settings.signKey;
    this.authorizeEndpoint = settings.authorizeEndpoint;
    this.tokenEndpoint = settings.tokenEndpoint;
    this.jwksEndpoint = settings.jwksEndpoint;
    this.userInfoEndpoint = settings.userInfoEndpoint;
    this.userInfoMethod = settings.userInfoMethod;
    this.endSessionEndpoint = settings.endSessionEndpoint;
    this.scope = settings.scope;
    this.responseType = settings.responseType;
    this.deactivatePCKE = settings.deactivatePCKE || false;
    this.acrValues = settings.acrValues || '';
    this.redirectURI = settings.redirectURI;
    this.logoutUri = settings.logoutURI;
    this.loggedIn = false;
    this.cpxLogin = false;
    this.authBearerUseJWTLogin = false;
    this.callUserInfos = settings.callUserInfos || false;
    if (settings.userInfosMapper) {
      this.userInfosMapper = JSON.parse(settings.userInfosMapper);
    }
    if (settings.loginCheckMapper) {
      this.loginCheckMapper = JSON.parse(settings.loginCheckMapper);
    }
    this.connectorToken = settings.connectorToken || undefined;
    this.apiType = settings.apiType;
    this.dcParams = settings.dcParams;
  }

  setUser(user) {
    this.user = user;
    if (this.expiredEventInterval) {
      clearTimeout(this.expiredEventInterval);
    }

    this.expiredEventInterval = setTimeout(() => this.renew(), (user.token.expires_in - 10) * 1000);
    // this.expiredEventInterval = setTimeout(() => this.renew(), (20 * 60) * 1000);
  }

  async setUserAndRenew(user) {
    this.user = user;
    return this.renew();
  }

  // eslint-disable-next-line class-methods-use-this
  async getSecurityValues() {
    return new Promise(async (resolve, reject) => {
      const state = sessionStorage.getItem('oidc_state') || generateId(50);
      sessionStorage.setItem('oidc_state', state);
      const nonce = sessionStorage.getItem('oidc_nonce') || generateId(50);
      sessionStorage.setItem('oidc_nonce', nonce);

      const { verifier: resultVerifier, challenge: resultChallenge } = await new Promise((innerResolve) => {
        getPkce(43, (error, { verifier, challenge } = {}) => {
          if (error) throw error;
          innerResolve({ verifier, challenge });
        });
      });
      const sessionPCKE = sessionStorage.getItem('oidc_pkce');
      const pkce = sessionPCKE ? JSON.parse(sessionPCKE) : { verifier: resultVerifier, challenge: resultChallenge };

      sessionStorage.setItem('oidc_pkce', JSON.stringify(pkce));

      resolve({ state, nonce, pkce });
    });
  }

  exchangeCodeForToken(params) {
    let parameters = params;
    const headers = {
      'Content-type': 'application/x-www-form-urlencoded',
    };
    if (this.connectorToken) {
      Object.assign(headers, {
        Authorization: `Bearer ${this.connectorToken}`,
      });
    }

    if (this.apiType === 'WS') {
      parameters = {
        ...parameters,
        dcparameters64: this.dcParams,
      };
    }
    return fetch(this.tokenEndpoint, {
      method: 'post',
      headers,
      body: objectToQueryParams(parameters),
    })
      .then(response => response.json())
      .then(json => json);
  }

  async validateIdToken(id_token, nonce) {
    const client = JwksClient({
      jwksUri: this.jwksEndpoint,
    });

    const getKey = (header, callback) => {
      client.getSigningKey(header.kid, (err, key) => {
        let signingKey;
        if ('publicKey' in key) {
          signingKey = key.publicKey;
        }
        if ('rsaPublicKey' in key) {
          signingKey = key.rsaPublicKey;
        }
        callback(null, signingKey);
      });
    };

    return new Promise((resolve, reject) => {
      verify(
        id_token,
        this.signkey || getKey,
        { nonce },
        (err, decoded) => {
          if (!err) {
            resolve({ valid: true, decodedToken: decoded });
          } else {
            reject(err.message);
          }
        },
      );
    });
  }

  async getUserInfos() {
    const { token } = this.user;
    const headers = this.userInfoMethod === 'GET' ? {
      Authorization: `${token.token_type} ${token.access_token}`,
    } : {
      'Content-type': 'application/x-www-form-urlencoded',
    };

    if (this.userInfoMethod === 'POST') {
      if (this.authBearerUseJWTLogin) {
        Object.assign(headers, {
          Authorization: `${token.token_type} ${token.access_token}`,
        });
      } else if (this.connectorToken) {
        Object.assign(headers, {
          Authorization: `Bearer ${this.connectorToken}`,
        });
      }
    }
    return fetch(
      this.userInfoEndpoint,
      {
        method: this.userInfoMethod,
        // @ts-ignore
        headers,
        body: this.userInfoMethod === 'POST' ? objectToQueryParams({
          type: token.token_type,
          credentials: token.access_token,
        }) : undefined,
      },
    ).then(response => response.json());
  }

  mapUserInfos(userInfos) {
    let mapper = pscUserInfosMapper;
    if (this.userInfosMapper) {
      mapper = this.userInfosMapper;
    }

    const mapped = Object.entries(mapper).reduce((o, [key, path]) => {
      const value = getObjectKeyFromPath(path, userInfos);
      return { ...o, [key]: value };
    }, {});

    if (!this.userInfosMapper) {
      const [professionIdentifier] = mapped.professionIdentifier;
      const [profession] = professionIdentifier.split('^');
      let speciality;
      let professionOid;
      let professionCode;
      let activities = [];
      if (userInfos.SubjectRefPro && userInfos.SubjectRefPro.exercices) {
        const exercice = userInfos.SubjectRefPro.exercices.find(e => e.codeProfession === profession);
        speciality = exercice[mapper.specialityCode];
        professionOid = exercice.codeCategorieProfessionnelle;


        if (exercice.activities) {
          // eslint-disable-next-line prefer-destructuring
          activities = exercice.activities;

          if (activities.length === 1 && profession === 21) {
            speciality = activities[0].codeSectionPharmacien;
          }
        }
      }

      professionCode = profession || 'SECRETARIAT_MEDICAL';
      let professionCodeOid = professionOid === 'E' ? '1.2.250.1.71.1.2.8' : '1.2.250.1.71.1.2.7';
      if (['SECRETARIAT_MEDICAL', 'DISPOSITIF'].includes(professionCode)) {
        professionCodeOid = '1.2.250.1.213.1.1.4.6';
      }

      if (this.overridePscUserInfos) {
        const { always, hpProfession, hpProfessionOid } = this.overridePscUserInfos;
        if (always === true || (!profession && hpProfession)) {
          professionCode = hpProfession;
          professionCodeOid = hpProfessionOid;
        }
      }

      return {
        hpName: mapped.hpName,
        hpGiven: mapped.hpGiven,
        hpProfession: professionCode,
        hpProfessionOid: professionCodeOid,
        hpSpeciality: speciality,
        hpInternalId: mapped.hpInternalId,
        activities,
      };
    }
    return mapped;
  }

  mapLoginCheck(userInfos) {
    let mapper = pscLoginCheckMapper;
    if (this.loginCheckMapper) {
      mapper = this.loginCheckMapper;
    }

    return Object.entries(mapper).reduce((o, [key, path]) => {
      const value = getObjectKeyFromPath(path, userInfos);
      return { ...o, [key]: value };
    }, {});
  }

  signout() {
    if (this.expiredEventInterval) {
      clearTimeout(this.expiredEventInterval);
    }

    this.loggedIn = false;
    this.user = null;
    sessionStorage.clear();
  }

  requestSignout() {
    if (this.expiredEventInterval) {
      clearTimeout(this.expiredEventInterval);
    }

    if (this.endSessionEndpoint && this.user) {
      const { token: { id_token } } = this.user;
      const queryString = objectToQueryParams({
        id_token_hint: id_token,
        post_logout_redirect_uri: this.redirectURI,
        state: generateId(50),
      });
      window.location.href = `${this.endSessionEndpoint}${queryString ? `?${queryString}` : undefined}`;
    } else {
      this.signout();
    }
  }

  async getAuthUrl() {
    const { state, nonce, pkce } = await this.getSecurityValues();

    const args = {
      client_id: this.clientId,
      redirect_uri: this.redirectURI,
      scope: this.scope,
      acr_values: this.acrValues,
      state,
      nonce,
      response_type: this.responseType,
    };
    if (!this.deactivatePCKE && pkce) {
      Object.assign(args, {
        code_challenge: pkce.challenge,
        code_challenge_method: 'S256',
      });
    }

    return `${this.authorizeEndpoint}?${objectToQueryParams(args)}`;
  }

  async signin() {
    window.location.href = await this.getAuthUrl();
  }

  async signinCallback(loginCheckValues, forceLoginCheck) {
    return new Promise(async (resolve, reject) => {
      const { state, nonce, pkce } = await this.getSecurityValues();

      const url = new URL(window.location.href);
      const urlState = url.searchParams.get('state');
      if (!urlState || urlState !== state) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          i_apiErrorCode: openIDErrors.unmatched_state,
          i_apiErrorType: errorTypes.openIDErrors,
        });
      } else {
        try {
          const code = url.searchParams.get('code');
          const params = {
            grant_type: 'authorization_code',
            code,
            redirect_uri: this.redirectURI,
            client_id: this.clientId,
          };

          if (!this.deactivatePCKE && pkce) {
            Object.assign(params, { code_verifier: pkce.verifier });
          } else {
            Object.assign(params, { client_secret: this.clientSecret });
          }

          const token = await this.exchangeCodeForToken(params);
          const { id_token } = token;

          if (!id_token) {
            if (token.error) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                i_apiErrorCode: token.error,
                i_apiErrorType: errorTypes.openIDErrors,
                ...token,
              });
            } else {
              reject(token);
            }
          }

          const { valid, decodedToken, error } = await this.validateIdToken(id_token, nonce);

          if (!valid) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({
              i_apiErrorCode: openIDErrors.invalid_token,
              i_apiErrorType: errorTypes.openIDErrors,
              ...error,
            });
          }

          this.user = {
            decoded_id_token: decodedToken,
            token,
          };


          let userInfos = decodedToken;
          let mappedUserInfos;

          if (!this.cpxLogin) {
            if (this.callUserInfos) {
              userInfos = await this.getUserInfos();
            }

            // compare values with logincheck
            if (forceLoginCheck) {
              const requiredValues = (env.REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES || 'name,given,rpps').split(',');
              const loginCheckOK = loginCheckValues && requiredValues.every(val => val in loginCheckValues);
              if (loginCheckOK) {
                const mappedLoginCheck = this.mapLoginCheck(userInfos);
                const nameOK = !requiredValues.includes('name') || loginCheckValues.name === mappedLoginCheck.name;
                const givenOK = !requiredValues.includes('given') || loginCheckValues.given === mappedLoginCheck.given;
                const rppsOK = !requiredValues.includes('rpps') || loginCheckValues.rpps === mappedLoginCheck.rpps;
                if (!(nameOK && givenOK && rppsOK)) {
                  if (this.expiredEventInterval) {
                    clearTimeout(this.expiredEventInterval);
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject({
                    i_apiErrorCode: openIDErrors.login_check_failed,
                    i_apiErrorType: errorTypes.openIDErrors,
                  });
                }
              } else {
              // eslint-disable-next-line prefer-promise-reject-errors
                reject({
                  i_apiErrorCode: openIDErrors.missing_login_check,
                  i_apiErrorType: errorTypes.openIDErrors,
                  provided: { ...loginCheckValues },
                  requiredValues,
                });
              }
            }

            mappedUserInfos = this.mapUserInfos(userInfos);
          }

          this.setUser({ ...this.user, profile: mappedUserInfos });
          this.loggedIn = true;
          resolve(this.user);
        } catch (e) {
          console.error('e', e);

          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            i_apiErrorCode: openIDErrors.unknown,
            i_apiErrorType: errorTypes.openIDErrors,
            error: e.message,
          });
        }
      }
    });
  }

  async renew() {
    return new Promise(async (resolve, reject) => {
      const { nonce } = await this.getSecurityValues();
      try {
        const token = await this.exchangeCodeForToken({
          grant_type: 'refresh_token',
          redirect_uri: this.redirectURI,
          client_id: this.clientId,
          client_secret: this.clientSecret,
          refresh_token: this.user.token.refresh_token,
        });
        const { id_token } = token;

        const { valid, decodedToken, error } = await this.validateIdToken(id_token, nonce);

        if (!valid) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            i_apiErrorCode: openIDErrors.invalid_token,
            i_apiErrorType: errorTypes.openIDErrors,
            ...error,
          });
        }

        if (this.dispatch) {
          this.dispatch(setUserJWT(token));
          this.dispatch(setMssPscToken(token));

          if (this.cpxLogin) {
            this.dispatch(getAction(
              commands.updateCpxAuthenticationToken,
              'updateCpxAuthenticationToken',
              {
                s_authenticationToken: token.access_token,
              },
            ));
          }
          if (this.authBearerUseJWTLogin) {
            this.dispatch(setPersistedConnectorConfiguration('connectorJWT', token.access_token));
          }
        }

        this.setUser({
          ...this.user,
          decoded_id_token: decodedToken,
          token,
        });
        resolve();
      } catch (e) {
        this.loggedIn = false;
        this.user = null;

        const url = new URL(window.location.href);
        if (url.pathname !== '/') {
          const error = createError(errorTypes.SoftwareErrors, softwareErrors.JWT_SESSION_EXPIRED);
          if (this.dispatch) {
            this.dispatch(logoutSuccess());
            this.dispatch(setModalError({ error }));
          }
          console.log('error jwt refresh', e);
        }
        reject(e);
      }
    });
  }
}
