import { push } from 'connected-react-router';
import {
  call, put, select, take,
} from 'redux-saga/effects';

import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  formatCreateDmpConnector,
  formatCreateEsDmpConnector, formatGetCardParams,
  formatReleaseApCvContext,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import {
  getApiType,
  getConfigurationValue,
  getCurrentPathname,
  getdmpconnectConnectorConfig,
  getInsiConfig,
  getInteropCodesFromState, getPracticeLocationSettingFromState,
  getSessionId,
  getUserConfiguration,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { API_TYPES, apiSections } from 'dmpconnectjsapp-base/constants';
import {
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
  clearSession,
  dmpCommandSuccessContextualized,
  clearSection,
  setLicenseStatus,
  setALDiConfiguration,
  setINSiConfiguration,
  setApCvConfiguration,
  dmpRemoteCommandSuccessContextualizedType,
  dmpRemoteCommandFailureContextualizedType, setDpConfiguration, initApplication, setUserConfiguration,
} from 'dmpconnectjsapp-base/actions';

import { errorTypes, softwareErrors } from 'dmpconnectjsapp-base/errors';
import { getSavedPinCode, savePinCode } from 'dmpconnectjsapp-base/sagas/errorHandlersSagas';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import { esLoginTypes } from 'dmpconnectjsapp-base/reducers/dmpconnectESConfiguration';
import { canUpload, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import {
  getAction,

  initApplicationFailure,
  initApplicationSuccess,
  resetApplication,
  setModalError,

  esLoginSuccess,
  setESConfiguration,
  setGlobalConfiguration,
  setLoginStepTwo,
  startCpxMonitoring,
  stopCpxMonitoring,

  esLoginFailed,
  initDocumentCache,
  login,
  loginSuccess,
  logout,
  logoutSuccess,
  setEsUser,
  setLoginReferer,
  requestEsLogout,

  setUserPreferences,
  setPersistedAppConfiguration,
} from '../actions';


import {
  getEsUser,
  isUserLoggedIn as isUserLoggedInAccessor,
  getDmpconnectPersistedConfiguration,
  getDmpconnectESConfiguration,
  getDmpconnectCPxConfiguration,
  getUserPreferences,
  isMssActive,
} from '../helpers';

import {
  checkRemoteControlStatus,
  remoteSendLoginSuccessNotification, remoteSendlogoutSuccessNotification, startRemoteControl,
  startRemoteMonitoring, stopRemoteControl,
  stopRemoteControlProcess, stopRemoteMonitoring,
} from '../actions/dmpconnectRemoteActions';
import { startVirtualPrinterMonitoring, stopVirtualPrinterMonitoring } from '../actions/dmpconnectVirtualPrinterActions';
import { redirectAfterLogin } from './utilsSagas';
import { cpxCardTypes } from '../constants/dmpConstants';
import { createError } from '../errors';
import { isDebugRemoteActive, isRemoteControlActive } from './remoteSagas';
import env from '../../envVariables';
import userManager from '../constants/userManager';
import { initMssState } from './mssSagas';
import { postMessageToIframeParent } from '../utils/iframe';
import { initializeUserPreferencesFromPersistantData } from './userSagas';
import { exceptionRoutes } from '../../router';
import { setDefaultLogsLevel } from './logsSaga';
import { extractPracticeLocationFromState } from '../helpers/practiceLocation';

const isAppInitialized = ({ dmpconnectInit }) => dmpconnectInit.applicationInitialized;
const IsUserLoggedIn = state => isUserLoggedInAccessor(state);
const getEsId = ({ dmpconnectESConfiguration }) => getConfigurationValue('es_id', dmpconnectESConfiguration);
const getLoginType = ({ dmpconnectESConfiguration }) => getConfigurationValue('loginType', dmpconnectESConfiguration);
const getApCvConfig = ({ dmpconnectApCvConfiguration }) => dmpconnectApCvConfiguration;
const shouldDestroyApCvContext = ({ dmpconnectApplication: { apCvContextToDestroy } }) => apCvContextToDestroy;
const getApCVBillingNumberAndSpeAmo = ({ dmpconnectCPxConfiguration }, accessRights) => ({
  billingNumber: getUserConfiguration(
    { s_internalId: accessRights.psId },
    'billingNumber',
    dmpconnectCPxConfiguration,
  ),
  codeSpeAMO: getUserConfiguration(
    { s_internalId: accessRights.psId },
    'codeSpeAMO',
    dmpconnectCPxConfiguration,
    '99',
  ),
});

const setLoginRefererHelper = function* (pathname, hash) {
  if (pathname + hash !== '/' && !exceptionRoutes.includes(pathname)) {
    if (pathname === '/') {
      yield put(setLoginReferer(`/home/${hash}`));
    } else {
      yield put(setLoginReferer(pathname + hash));
    }
  }
};

export const checkIfUserIsLoggedIn = function* (payload) {
  const {
    location: { pathname, hash },
    action,
    isFirstRendering,
  } = payload;

  const loggedIn = yield select(IsUserLoggedIn);
  const apiType = yield select(getApiType);

  if (exceptionRoutes.includes(pathname)) {
    return loggedIn;
  }
  if (loggedIn !== true && pathname + hash !== '/') {
    yield call(setLoginRefererHelper, pathname, hash);
    yield put(push('/'));
    return false;
  }

  if (pathname !== '/' && action === 'POP' && apiType !== API_TYPES.REST) {
    // check if the session is still alive

    yield put(getAction(
      commands.getSessionState,
      apiSections.SESSION_STATE_SECTION,
      null,
      { silentError: true },
    ));

    const result = yield take([
      dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION),
      dmpCommandFailureContextualizedType(apiSections.SESSION_STATE_SECTION),
    ]);

    if (result.type === dmpCommandFailureContextualizedType(apiSections.SESSION_STATE_SECTION)) {
      yield call(setLoginRefererHelper, pathname, hash);
      return false;
    }
  }

  /**
   * Manage the case where the user go back to the root through browser history
   */
  if (loggedIn && !isFirstRendering && pathname === '/') {
    yield put(logout());
    return false;
  }
  return loggedIn;
};

export const logoutProcess = function* (action) {
  const { redirectTo = '/' } = action;
  const apiType = yield select(getApiType);
  const apCvConfig = yield select(getApCvConfig);
  const apCvContextToDestroy = yield select(shouldDestroyApCvContext);

  if (userManager.loggedIn === true) {
    yield put(requestEsLogout());
  } else {
    if (apCvConfig.active && apCvContextToDestroy === true) {
      const { accessRights } = yield select(getAccessRightsProps);
      const { billingNumber, codeSpeAMO } = yield select(getApCVBillingNumberAndSpeAmo, accessRights);
      if (billingNumber && codeSpeAMO) {
        yield put(clearSection(apiSections.RELEASE_APCV_CONTEXT));
        yield put(getAction(
          commands.releaseApCvContext,
          apiSections.RELEASE_APCV_CONTEXT,
          formatReleaseApCvContext({
            idam: apCvConfig.idam,
            numAm: apCvConfig.numAm,
            lpsName: apCvConfig.lpsName,
            lpsVersion: apCvConfig.lpsVersion,
            codeSpeAMO,
            billingNumber,
          }),
          { silentError: true },
        ));
      }
    }


    if (apiType === API_TYPES.WS) {
      yield put(stopCpxMonitoring());
      yield put(stopVirtualPrinterMonitoring());
      yield put(stopRemoteControlProcess(false));
      yield take(
        [
          dmpCommandSuccessContextualizedType(apiSections.STOP_REMOTE_CONTROL),
          dmpCommandFailureContextualizedType(apiSections.STOP_REMOTE_CONTROL),
        ],
      );
    }

    const sessionId = yield select(getSessionId);
    if (sessionId) {
      yield put(getAction(
        commands.closeSession,
        apiSections.SESSION_SECTION,
        null,
        { synchronous: true },
      ));
      yield take(
        [
          dmpCommandSuccessContextualizedType(apiSections.SESSION_SECTION),
          dmpCommandFailureContextualizedType(apiSections.SESSION_SECTION),
        ],
      );
    }
    yield put(clearSection(apiSections.SESSION_SECTION));
    yield put(logoutSuccess());

    postMessageToIframeParent({
      type: 'logout',
      desc: 'User has been logged out.',
    });

    const appInitialized = yield select(isAppInitialized);
    if (appInitialized) {
      yield put(clearSession());
      yield put(resetApplication());
    }

    if (redirectTo === '/') {
      yield put(initApplication());
    }

    const pathname = yield select(getCurrentPathname);
    if (pathname !== redirectTo && pathname !== '/login-token') {
      yield put(push(redirectTo));
    }
  }
};

export const handleReadCpxCard = function* (action) {
  const {
    pinCode, readerNumber, doLogin = true, forceLoginStepTwo = false,
  } = action;

  const dmpConnectPersistedAppConfiguration = yield select(getDmpconnectPersistedConfiguration);
  const userPreferences = yield select(getUserPreferences);
  const cpxLoginType = getConfigurationValue('cpxLoginType', dmpConnectPersistedAppConfiguration);
  const pcscReaders = yield select(({
    dmpconnect: {
      [apiSections.PCSC_READERS_SECTION]: {
        Readers = [],
      } = {},
    } = {},
  }) => Readers);

  const reader = pcscReaders && pcscReaders.length > 0 ? pcscReaders[readerNumber] : undefined;

  savePinCode(pinCode);
  yield put(stopCpxMonitoring());
  // CPX card object creation
  yield put(getAction(
    commands.getCpxCard,
    apiSections.CPX_SECTION,
    formatGetCardParams(Number(readerNumber), reader ? reader.s_name : ''),
    { synchronous: true },
  ));
  const getCpxCardResult = yield take(
    [
      dmpCommandSuccessContextualizedType(apiSections.CPX_SECTION),
      dmpCommandFailureContextualizedType(apiSections.CPX_SECTION),
    ],
  );

  if (getCpxCardResult.type === dmpCommandFailureContextualizedType(apiSections.CPX_SECTION)) return;
  // CPX card reading
  yield put(getAction(
    commands.readCpxCard,
    apiSections.CPX_CARD_SECTION,
    cpxLoginType === 'OPENID' ? { s_authenticationToken: pinCode } : { s_pinCode: pinCode },
    {
      synchronous: true,
      contextParams: { code: encodeIns(pinCode) },
    },
  ));
  const readCpxCardResult = yield take(
    [
      dmpCommandSuccessContextualizedType(apiSections.CPX_CARD_SECTION),
      dmpCommandFailureContextualizedType(apiSections.CPX_CARD_SECTION),
    ],
  );

  if (readCpxCardResult.type === dmpCommandFailureContextualizedType(apiSections.CPX_CARD_SECTION)) {
    yield put(getAction(
      commands.getCpxStatus,
      apiSections.CPX_STATUS_SECTION,
    ));
    return;
  }

  // si pas de situations d'exercice, afficher l'étape 2
  const { PracticeLocations } = readCpxCardResult.data;
  if (!PracticeLocations || PracticeLocations.length === 0) {
    // return error
    yield put(initApplicationFailure(readCpxCardResult.data));
    const error = createError(
      errorTypes.SoftwareErrors,
      softwareErrors.NO_PRACTICE_LOCATIONS,
    );
    yield put(setModalError({ error }));
  }

  // check card type
  if (Number(env.REACT_APP_PRODUCTON_MODE) === 1) {
    const { i_cpxCardType } = readCpxCardResult.data;

    if (i_cpxCardType !== cpxCardTypes.PROD) {
      // return error
      yield put(initApplicationFailure(readCpxCardResult.data));
      const error = createError(
        errorTypes.SoftwareErrors,
        softwareErrors.DEMO_CPX_IN_PROD,
      );
      yield put(setModalError({ error }));
      return;
    }
  }

  // IF success we start monitoring
  yield put(startCpxMonitoring());

  if (doLogin === true) {
    const dmpConnectCPxConfiguration = yield select(getDmpconnectCPxConfiguration);
    const { data: cpxCard } = readCpxCardResult;
    const cpxSerialNumber = yield getUserConfiguration(cpxCard, 'cpxSerialNumber', dmpConnectCPxConfiguration);
    const fromRemote = yield getUserConfiguration(cpxCard, 'fromRemote', dmpConnectCPxConfiguration);
    let practiceSetting = yield getUserConfiguration(cpxCard, 'practiceSetting', dmpConnectCPxConfiguration);
    const practiceLocationSettingDetails = yield getUserConfiguration(cpxCard, 'practiceLocationSettingDetails', dmpConnectCPxConfiguration);
    let practiceLocationSetting = yield select(getPracticeLocationSettingFromState);
    let customPracticeLocationStructureName = yield getUserConfiguration(cpxCard, 'customPracticeLocationStructureName', dmpConnectCPxConfiguration);
    let customPracticeLocationStructureIdType = yield getUserConfiguration(cpxCard, 'customPracticeLocationStructureIdType', dmpConnectCPxConfiguration);
    let customPracticeLocationStructureId = yield getUserConfiguration(cpxCard, 'customPracticeLocationStructureId', dmpConnectCPxConfiguration);
    let customPracticeLocationActivitySector = yield getUserConfiguration(cpxCard, 'customPracticeLocationActivitySector', dmpConnectCPxConfiguration);
    const healthcareSetting = yield getUserConfiguration(cpxCard, 'healthcareSetting', dmpConnectCPxConfiguration);
    const minimumMinorAge = yield getConfigurationValue('minimumMinorAge', userPreferences);

    const practiceSettings = yield select(getInteropCodesFromState, 'practiceSettings');
    const healthcareSettings = yield select(getInteropCodesFromState, 'healthcareSettings');
    const billingNumber = getUserConfiguration(cpxCard, 'billingNumber', dmpConnectCPxConfiguration);
    const insiConfig = yield select(getInsiConfig);
    const apCvConfig = yield select(getApCvConfig);

    if (cpxSerialNumber !== cpxCard.s_cpxSerialNumber && !fromRemote) {
      // empty the saved infos for the old card
      yield put(setUserConfiguration(cpxCard.s_internalId, undefined));
    }

    if (
      !forceLoginStepTwo
        && (cpxSerialNumber === cpxCard.s_cpxSerialNumber || fromRemote)
      && practiceSettings.find(item => item.code === practiceSetting)
      && (
        practiceLocationSetting !== null
        || (
          customPracticeLocationStructureName !== ''
          && customPracticeLocationStructureId !== ''
          && customPracticeLocationActivitySector !== ''
        )
      )
      && healthcareSettings.find(item => item.code === healthcareSetting)
      && (
        (!insiConfig.active && !apCvConfig.active)
        || ((insiConfig.active || apCvConfig.active) && billingNumber !== null)
      )
    ) {
      if (fromRemote) {
        yield put(setUserConfiguration(cpxCard.s_internalId, { cpxSerialNumber: cpxCard.s_cpxSerialNumber, fromRemote: false }));
        practiceSetting = yield getUserConfiguration(cpxCard, 'practiceSetting', dmpConnectCPxConfiguration);
        const {
          cpsPracticeLocationIndice,
          structureName,
          structureIdType,
          structureId,
          activitySector,
        } = yield select(extractPracticeLocationFromState, cpxCard.s_internalId);
        practiceLocationSetting = cpsPracticeLocationIndice >= 0 ? cpsPracticeLocationIndice : null;
        customPracticeLocationStructureName = structureName;
        customPracticeLocationStructureIdType = structureIdType;
        customPracticeLocationStructureId = structureId;
        customPracticeLocationActivitySector = activitySector;
      }
      if (minimumMinorAge === null) {
        yield put(setUserPreferences({ minimumMinorAge: Number(env.REACT_APP_MINIMUM_MINOR_AGE || 0) }, true));
      }

      if (practiceLocationSetting !== null && !practiceLocationSettingDetails) {
        if (PracticeLocations[practiceLocationSetting]) {
          yield put(setUserConfiguration(
            cpxCard.s_internalId,
            { practiceLocationSettingDetails: PracticeLocations[practiceLocationSetting] },
          ));
        }
      }

      yield put(login({
        pinCode,
        practiceSetting,
        cpsPracticeLocationIndice: practiceLocationSetting,
        customPracticeLocationStructureName,
        customPracticeLocationStructureIdType,
        customPracticeLocationStructureId,
        customPracticeLocationActivitySector,
      }));
    } else {
      yield put(setLoginStepTwo(true));
    }
  }
};

export const loginProcess = function* (
  {
    auto = false,
    pinCode,
    practiceSetting,
    cpsPracticeLocationIndice,
    customPracticeLocationStructureName,
    customPracticeLocationStructureIdType,
    customPracticeLocationStructureId,
    customPracticeLocationActivitySector,
  },
) {
  yield put(stopCpxMonitoring());

  yield put(setLoginStepTwo(false));
  const dmpConfiguration = yield select(getdmpconnectConnectorConfig);
  const serverName = yield getConfigurationValue('serverName', dmpConfiguration);

  let params = formatCreateDmpConnector(
    auto === true ? getSavedPinCode() : pinCode,
    serverName,
    practiceSetting,
    cpsPracticeLocationIndice,
    customPracticeLocationStructureName,
    customPracticeLocationStructureIdType,
    customPracticeLocationStructureId,
    customPracticeLocationActivitySector,
  );

  // si on utilise une situation d'exercice créée manuellement, on désactive apcv
  if (cpsPracticeLocationIndice === null) {
    yield put(setApCvConfiguration('active', false));
  } else {
    // sinon on remet la valeur venant de la configuration
    yield put(setApCvConfiguration('active', Number(env.REACT_APP_APCV) === 1));
  }

  const dmpConnectPersistedAppConfiguration = yield select(getDmpconnectPersistedConfiguration);
  const cpxLoginType = getConfigurationValue('cpxLoginType', dmpConnectPersistedAppConfiguration);
  if (cpxLoginType === 'OPENID') {
    const { s_pinCode, ...rest } = params;
    params = {
      ...rest,
      s_authenticationToken: pinCode,
    };
  }

  yield put(getAction(
    commands.createDMPConnector,
    apiSections.CONNECTOR_SECTION,
    params,
    { synchronous: true },
  ));
  const result = yield take(
    [
      dmpCommandSuccessContextualizedType(apiSections.CONNECTOR_SECTION),
      dmpCommandFailureContextualizedType(apiSections.CONNECTOR_SECTION),
    ],
  );
  if (result.type === dmpCommandFailureContextualizedType(apiSections.CONNECTOR_SECTION)) {
    yield put(initApplicationFailure(result.data));
    return;
  }
  yield put(setLicenseStatus(true));
  yield put(initApplicationSuccess());
  savePinCode(null);

  const cpxCard = yield select(state => state.dmpconnect[apiSections.CPX_CARD_SECTION]);
  if (!cpxCard.i_cpxCardType) {
    // refresh sessionState to get the cpxType, and continue only after we got it
    yield put(getAction(
      commands.getSessionState,
      apiSections.SESSION_STATE_SECTION,
      null,
      {
        synchronous: true,
        contextExtra: { noCardTypeCheck: false },
      },
    ));

    yield take(dmpCommandSuccessContextualizedType(apiSections.CPX_CARD_TYPE_SECTION));
  }
  yield put(loginSuccess());
  yield put(startCpxMonitoring());
  const isRemoteActive = yield select(isRemoteControlActive);
  if (isRemoteActive) {
    yield put(remoteSendLoginSuccessNotification());
  }
  yield call(redirectAfterLogin);
};

const getAndSetEsInfos = function* (esId) {
  yield put(getAction(
    commands.getEsInfos,
    apiSections.GET_ES_INFOS,
    { s_esId: esId },
  ));

  const esInfos = yield take([
    dmpCommandSuccessContextualizedType(apiSections.GET_ES_INFOS),
    dmpCommandFailureContextualizedType(apiSections.GET_ES_INFOS),
  ]);

  if (esInfos.type === dmpCommandSuccessContextualizedType(apiSections.GET_ES_INFOS)) {
    yield put(setESConfiguration('practiceLocationName', esInfos.data.s_structureName));
    yield put(setESConfiguration('activitySector', esInfos.data.s_activitySector));
  }
};

const getAndSetUserInfos = function* (esId, userLogin, userPassword) {
  yield put(getAction(
    commands.getUserInfos,
    apiSections.GET_USER_INFOS,
    { s_login: userLogin, s_password: userPassword, s_passwordSha1: userPassword },
  ));

  const userInfos = yield take([
    dmpCommandSuccessContextualizedType(apiSections.GET_USER_INFOS),
    dmpCommandFailureContextualizedType(apiSections.GET_USER_INFOS),
  ]);

  if (userInfos.type === dmpCommandSuccessContextualizedType(apiSections.GET_USER_INFOS)) {
    yield put(setEsUser({
      hpGiven: userInfos.data.s_given,
      hpInternalId: userInfos.data.s_internalId,
      hpName: userInfos.data.s_name,
      hpProfession: userInfos.data.s_professionCode,
      hpProfessionOid: userInfos.data.s_professionOid,
      hpSpeciality: userInfos.data.s_specialty,
      esId,
    }));
  }
};

export const esLoginProcess = function* ({ userLogin, userPassword }) {
  const esId = yield select(getEsId);
  const loginType = yield select(getLoginType);
  const dmpConfiguration = yield select(getdmpconnectConnectorConfig);
  const dmpconnectESConfiguration = yield select(getDmpconnectESConfiguration);
  const serverName = yield getConfigurationValue('serverName', dmpConfiguration);
  const hpAuthenticationMode = yield getConfigurationValue('hpAuthenticationMode', dmpconnectESConfiguration);
  const activitySector = yield getConfigurationValue('activitySector', dmpconnectESConfiguration);
  const practiceSetting = yield getConfigurationValue('practiceSetting', dmpconnectESConfiguration);
  const practiceLocationName = yield getConfigurationValue('practiceLocationName', dmpconnectESConfiguration);
  const esUser = yield select(getEsUser);

  if (loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD) {
    yield put(setEsUser(null));
    yield call(getAndSetEsInfos, esId);
  }

  // creation du connecteur
  yield put(getAction(
    commands.createEsDMPConnector,
    apiSections.CONNECTOR_SECTION,
    formatCreateEsDmpConnector(
      loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD
        ? {
          loginType,
          serverName,
          practiceLocationName,
          activitySector,
          practiceSetting,
          login: userLogin,
          password: userPassword,
        }
        : {
          loginType,
          serverName,
          practiceLocationName,
          activitySector,
          practiceSetting,
          hpName: esUser.hpName,
          hpGiven: esUser.hpGiven,
          hpProfession: esUser.hpProfession,
          hpProfessionOid: esUser.hpProfessionOid,
          hpSpeciality: esUser.hpSpeciality,
          hpInternalId: esUser.hpInternalId,
          hpAuthenticationMode,
        },
    ),
    { synchronous: true },
  ));

  const result = yield take(
    [
      dmpCommandSuccessContextualizedType(apiSections.CONNECTOR_SECTION),
      dmpCommandFailureContextualizedType(apiSections.CONNECTOR_SECTION),
    ],
  );
  if (result.type === dmpCommandFailureContextualizedType(apiSections.CONNECTOR_SECTION)) {
    yield put(esLoginFailed(result.data));
    return;
  }

  if (loginType === esLoginTypes.CONNECTOR_LOGIN_PASSWD) {
    yield call(getAndSetUserInfos, esId, userLogin, userPassword);
  }

  yield put(esLoginSuccess());
  yield put(initApplicationSuccess());
  const isRemoteActive = yield select(isRemoteControlActive);
  if (isRemoteActive) {
    yield put(remoteSendLoginSuccessNotification());
  }
  yield call(redirectAfterLogin);
};

const getRemoteControlStatus = function* () {
  yield put(checkRemoteControlStatus());
  const status = yield take([
    dmpCommandSuccessContextualizedType(apiSections.REMOTE_CONTROL_STATUS),
    dmpCommandFailureContextualizedType(apiSections.REMOTE_CONTROL_STATUS),
  ]);

  if (status && status.type === dmpCommandSuccessContextualizedType(apiSections.REMOTE_CONTROL_STATUS)) {
    return status.data;
  }
  return false;
};

export const remoteMonitoringStartProcess = function* (checkStatus = false) {
  const isRemoteActive = yield select(isDebugRemoteActive);

  if (isRemoteActive) {
    let remoteStatus = yield call(getRemoteControlStatus);

    if (remoteStatus && remoteStatus.i_running === 0) {
      yield put(startRemoteControl(checkStatus));
      yield put(setGlobalConfiguration('forceDesactivateRemoteControl', false));
      yield take(dmpCommandSuccessContextualizedType(apiSections.START_REMOTE_CONTROL));
      remoteStatus = yield call(getRemoteControlStatus);
    }
    if (remoteStatus && remoteStatus.i_monitoringActive === 0 && remoteStatus.i_running === 1) {
      yield put(startRemoteMonitoring());
    }
  } else {
    yield put(setGlobalConfiguration('forceDesactivateRemoteControl', true));
  }
};

export const remoteMonitoringStopProcess = function* (checkStatus = false) {
  const isRemoteActive = yield select(isDebugRemoteActive);

  if (isRemoteActive) {
    const remoteStatus = yield call(getRemoteControlStatus);
    if (remoteStatus && remoteStatus.i_monitoringActive === 1) {
      yield put(stopRemoteMonitoring());
    }

    const remoteStatusAgain = yield call(getRemoteControlStatus);
    if (remoteStatusAgain && remoteStatusAgain.i_monitoringActive === 0 && remoteStatusAgain.i_running === 1) {
      yield put(remoteSendlogoutSuccessNotification());
      // wait for notification result before to stop remote control to avoid errors
      yield take([
        dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_LOGIN_NOTIFICATION),
        dmpRemoteCommandFailureContextualizedType(apiSections.REMOTE_LOGIN_NOTIFICATION),
      ]);

      yield put(stopRemoteControl(checkStatus));
    }
  } else {
    yield put(setGlobalConfiguration('forceDesactivateRemoteControl', true));
    yield put(dmpCommandSuccessContextualized({}, { section: apiSections.STOP_REMOTE_CONTROL }, {}));
  }
};

export const initVirtualPrinter = function* () {
  const apiType = yield select(getApiType);
  const { accessRights } = yield select(getAccessRightsProps);
  if (apiType === API_TYPES.WS && canUpload(accessRights)) {
    yield put(startVirtualPrinterMonitoring());
  }
};

export const getAndActivateFeatures = function* () {
  yield put(getAction(
    commands.activeFeatures,
    apiSections.GET_ACTIVE_FEATURES,
    null,
    {
      silentError: true,
    },
  ));

  const result = yield take([
    dmpCommandSuccessContextualizedType(apiSections.GET_ACTIVE_FEATURES),
    dmpCommandFailureContextualizedType(apiSections.GET_ACTIVE_FEATURES),
  ]);

  if (result.type === dmpCommandSuccessContextualizedType(apiSections.GET_ACTIVE_FEATURES)) {
    const { data: { Features = [] } } = result;
    const {
      dmpconnectConnectorConfig,
      dmpconnectApCvConfiguration,
      dmpconnectALDiConfiguration,
      dmpconnectINSiConfiguration,
      dmpconnectDpConfiguration,
      dmpConnectPersistedAppConfiguration,
    } = yield select(state => state);
    // MSS

    const mssActive = yield select(isMssActive);
    const newMssActive = mssActive && Features.includes('MSS');
    if (mssActive !== newMssActive) {
      yield put(setUserPreferences({
        mssActive: newMssActive,
      }));
    }
    // Remote Control
    yield put(setGlobalConfiguration(
      'debugActivateRemoteControl',
      getConfigurationValue('debugActivateRemoteControl', dmpconnectConnectorConfig)
      && Features.includes('REMOTE'),
    ));
    // ALD
    yield put(setALDiConfiguration(
      'active',
      getConfigurationValue('active', dmpconnectALDiConfiguration)
      && Features.includes('ALD'),
    ));
    // INSI
    yield put(setINSiConfiguration(
      'active',
      getConfigurationValue('active', dmpconnectINSiConfiguration)
      && Features.includes('INSI'),
    ));
    // DP
    yield put(setDpConfiguration(
      'dpActive',
      getConfigurationValue('dpActive', dmpconnectDpConfiguration)
      && Features.includes('DP'),
    ));
    // ApCV
    yield put(setApCvConfiguration(
      'active',
      getConfigurationValue('active', dmpconnectApCvConfiguration)
      && Features.includes('ApCV'),
    ));

    // WIRELESS
    yield put(setPersistedAppConfiguration(
      'wirelessModeOlaqin',
      getConfigurationValue('wirelessModeOlaqin', dmpConnectPersistedAppConfiguration)
      && Features.includes('WIRELESS'),
    ));
  }
};

export const initStatesAndMonitoring = function* () {
  const isLogged = yield select(isUserLoggedInAccessor);
  yield call(setDefaultLogsLevel);
  yield call(initializeUserPreferencesFromPersistantData);
  yield call(initMssState);
  yield put(initDocumentCache());
  const apiType = yield select(getApiType);
  if (apiType === API_TYPES.WS) {
    yield call(remoteMonitoringStartProcess);
    if (isLogged) {
      yield call(initVirtualPrinter);
    }
  }
};
