import { dmpconnectUserActionConstants } from '../constants';

const initialState = {};


export function userRights(state = initialState, action) {
  switch (action.type) {
    case dmpconnectUserActionConstants.SET_USER_RIGHTS:
      return action.accessRights;
    default:
      return state;
  }
}
