import merge from 'lodash.merge';
import { authenticationTypes } from 'dmpconnectjsapp-base/rules/accessRights';
import { findDocumentParamsDefault } from 'dmpconnectjsapp-base/helpers/findDocuments';
import {
  dmpconnectApplicationActionConstants,
  dmpconnectUserActionConstants,
} from '../constants';

const initialState = {
  expiredSession: false,
  removedCPX: false,
  loggedIn: false,
  authenticationType: null,
  loginReferer: null,
  logoutPending: false,
  findPatientsParams: {},
  findPatientsIns: null,
  findDocumentsParams: findDocumentParamsDefault,
  forceSecretConnexion: {},
  openedCategories: [],
  loginStepTwo: false,
  lstdocs_sorted_column: 'submissionDate',
  lstdocs_sorted_desc: true,
  esLoginfailedMessage: null,
  requestESLogout: false,
  esUser: null,
  userJWT: null,
  pinCode: null,
  overridePscUserInfos: {
    hpProfession: undefined,
    hpProfessionOid: undefined,
    always: false,
  },
};

const resetFindDocumentsParamsState = (state) => {
  const { findDocumentsParams, openedCategories, ...all } = state;
  return merge({},
    all,
    {
      findDocumentsParams: initialState.findDocumentsParams,
      openedCategories: initialState.openedCategories,
    });
};

const setFindPatientsParamsState = (state, action) => {
  const { findPatientsParams, ...all } = state;
  return merge({}, all, { findPatientsParams: action.findPatientsParams });
};

const setFindDocumentParamsCategories = (state, action) => {
  const { findDocumentsParams: { categories, ...params }, ...all } = state;
  const { categories: newCategories } = action;
  return {
    ...all,
    findDocumentsParams: {
      categories: newCategories,
      ...params,
    },
  };
};

const setFindDocumentOpenedCategories = (state, action) => {
  const { openedCategories, ...all } = state;
  const { categories: newCategories } = action;
  return {
    ...all,
    openedCategories: newCategories,
  };
};

export function dmpconnectUser(state = initialState, action) {
  switch (action.type) {
    case dmpconnectUserActionConstants.SET_PIN_CODE:
      return merge({}, state, { pinCode: action.pinCode });
    case dmpconnectUserActionConstants.EXPIRED_SESSION:
      return merge({}, state, { expiredSession: action.expiredSession });
    case dmpconnectUserActionConstants.REMOVED_CPX:
      return merge({}, state, { removedCPX: action.removedCPX });
    case dmpconnectUserActionConstants.DMPC_LOGOUT:
      return merge({}, state, { logoutPending: true });
    case dmpconnectUserActionConstants.DMPC_LOGOUT_SUCCESS:
      return merge({}, state, {
        loginReferer: state.loginReferer,
        loggedIn: false,
        authenticationType: null,
        esUser: null,
        userJWT: null,
        logoutPending: false,
        loginStepTwo: false,
        esLoginfailedMessage: null,
        requestESLogout: false,
        findPatientsParams: {},
        findPatientsIns: null,
        pinCode: null,
      });
    case dmpconnectApplicationActionConstants.DMPC_INIT_APPLICATION:
      return merge({}, state, {
        logoutPending: false, loginStepTwo: false, esLoginfailedMessage: null, requestESLogout: false,
      });
    case dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS:
      return merge({}, state, {
        loggedIn: true, loginStepTwo: false, authenticationType: authenticationTypes.DIRECT, pinCode: null,
      });
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS:
      return merge({}, state, { loggedIn: true, authenticationType: authenticationTypes.INDIRECT, pinCode: null });
    case dmpconnectUserActionConstants.DMPC_SET_LOGIN_REFERER:
      return { ...state, loginReferer: action.loginReferer };
    case dmpconnectUserActionConstants.DMPC_SET_FIND_PATIENTS_PARAMS:
      return setFindPatientsParamsState(state, action);
    case dmpconnectUserActionConstants.DMPC_SET_FIND_PATIENTS_INS:
      return merge({}, state, { findPatientsIns: action.ins });
    case dmpconnectUserActionConstants.DMPC_CLEAR_LOGIN_REFERER:
      return merge({}, state, { loginReferer: null });
    case dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAM_CATEGORIES:
      return setFindDocumentParamsCategories(state, action);
    case dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_OPENED_CATEGORIES:
      return setFindDocumentOpenedCategories(state, action);
    case dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAM:
      return merge({},
        state,
        {
          findDocumentsParams: merge({},
            state.findDocumentsParams,
            { [action.searchParam]: action.searchParamValue }),
        });
    case dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAMS:
      return merge({},
        state,
        {
          findDocumentsParams: merge({},
            state.findDocumentsParams,
            action.searchParams),
        });
    case dmpconnectUserActionConstants.DMPC_RESET_FIND_DOCUMENTS_PARAMS:
      return resetFindDocumentsParamsState(state);
    case dmpconnectUserActionConstants.DMPC_SET_FORCE_SECRET_CONNEXION:
      return merge({}, state, { forceSecretConnexion: { [action.ins]: action.force } });
    case dmpconnectUserActionConstants.DMPC_SET_LOGIN_STEP_TWO:
      return merge({}, state, { loginStepTwo: action.status });
    case dmpconnectUserActionConstants.DMPC_SET_LSTDOCS_SORTED_COLUMN:
      return merge({}, state, { lstdocs_sorted_column: action.column, lstdocs_sorted_desc: action.desc });
    case dmpconnectUserActionConstants.DMPC_LOGIN:
      return merge({}, state, { expiredSession: false, removedCPX: false });
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN:
      return merge({}, state, { esLoginfailedMessage: null, expiredSession: false, removedCPX: false });
    case dmpconnectUserActionConstants.DMPC_SET_ES_USER:
      return merge({}, state, { esUser: action.user });
    case dmpconnectUserActionConstants.DMPC_ES_LOGIN_FAILED:
      return { ...state, esLoginfailedMessage: action.message, esUser: null };
    case dmpconnectUserActionConstants.DMPC_ES_LOGOUT:
      return merge({}, state, { requestESLogout: true, logoutPending: true });
    case dmpconnectUserActionConstants.DMPC_SET_USER_JWT:
      return merge({}, state, { userJWT: action.token });
    case dmpconnectUserActionConstants.SET_OVERRIDE_PSC_USERINFO:
      return { ...state, overridePscUserInfos: action.overridePscUserInfos };
    default:
      return state;
  }
}
