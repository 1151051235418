import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';
import PopoverStickOnHover from '../Common/Modal/PopoverStickOnHover';
import MssPsOverlayFooter from '../mss/MssPsOverlayFooter';
import { isMssActive } from '../../dmpconnect/helpers';

const PsNameWithOverlay = ({
  professionDesc,
  name,
  given,
  specialty,
  rpps,
  institution,
  id,
  popoverPlacement,
  email,
  label,
  ins,
}) => (
  <PopoverStickOnHover
    id={id}
    component={(
      <>
        <div className="popover-header">
          {!label && !name && !given ? (
            <>
              <span>Non Spécifié</span>
              <i className="ic-exclamation-warning" />
            </>
          ) : (
            <>
              {given}
              {' '}
              {name || label}
            </>
          )}
        </div>
        <div className="popover-body">
          {professionDesc && (
          <div className="typography-default-text-title">{professionDesc}</div>
          )}
          {specialty && (
          <div className="typography-default-text">{specialty}</div>
          )}
          {(professionDesc || specialty) && (rpps || institution) && <div className="mb-3" />}
          {rpps && (
          <div className="typography-default-text text-gray-400">{`RPPS : ${rpps}`}</div>
          )}
          {ins && (
          <div className="typography-default-text text-gray-400">{`Matricule INS : ${ins}`}</div>
          )}
          {institution && (
          <div className="typography-default-text text-gray-400">{`Etablissement : ${institution}`}</div>
          )}
        </div>
        <div className="popover-footer d-flex align-items-center justify-content-between">
          <MssPsOverlayFooter name={name} given={given} rpps={rpps} email={email} />
        </div>
      </>
      )}
    placement={popoverPlacement}
    className="d-flex justify-content-between align-items-center flex-grow-1"
  >
    <>
      {!label && !name && !given ? (
        <>
          <span className="ps-name-hover">Non Spécifié</span>
          <i className="ic-exclamation-warning" />
        </>
      ) : (
        <span className="ps-name-hover">
          {label || `${given} ${name}`}
        </span>
      )}
    </>
  </PopoverStickOnHover>

);

PsNameWithOverlay.propTypes = {
  specialty: PropTypes.string,
  professionDesc: PropTypes.string,
  rpps: PropTypes.string,
  institution: PropTypes.string,
  name: PropTypes.string,
  given: PropTypes.string,
  id: PropTypes.string,
  popoverPlacement: PropTypes.string,
  email: PropTypes.string,
  label: PropTypes.string,
  ins: PropTypes.string,
};

PsNameWithOverlay.defaultProps = {
  specialty: '',
  rpps: '',
  professionDesc: '',
  institution: '',
  name: '',
  given: '',
  id: '',
  popoverPlacement: 'bottom',
  email: '',
  label: '',
  ins: '',
};

function mapStateToProps(state, props) {
  const { rpps, name, given } = props;
  const {
    dmpconnect: {
      [apiSections.MSS_GET_HP_INFOS]: {
        [rpps]: getHpSection = {},
      } = {},
    },
  } = state;

  let mssName = name;
  let mssGiven = given;
  if (isMssActive(state) && isReady(getHpSection)) {
    getHpSection.PSList.some(({ s_name, s_given }) => {
      mssName = s_name;
      mssGiven = s_given;
      return true;
    });
  }
  return {
    name: mssName,
    given: mssGiven,
  };
}

export default connect(mapStateToProps)(PsNameWithOverlay);
